<script setup lang="ts">
import { dayjs } from '~~/utils/date';

interface Input {
  date?: number;
  month?: number;
  year?: number;
}

const parseValue = (value?: string) => {
  const date = dayjs(value);
  let result: Input = {
    date: props.disableDay ? 1 : undefined,
    month: undefined,
    year: undefined,
  };

  if (value && date.isValid()) {
    result = { date: date.date(), month: date.month(), year: date.year() };
  }

  return result;
};

const prepareInput = (input: Input) => {
  const date = dayjs()
    .date(input.date || 0)
    .month(input.month || 0)
    .year(input.year || 0);
  return date.format('YYYY-MM-DD');
};

const props = defineProps<{
  clearable?: boolean;
  disabled?: boolean;
  disableDay?: boolean;
  modelValue?: string;
}>();
const emit = defineEmits<{ (e: 'update:modelValue', v: string): void }>();
const input = ref(parseValue(props.modelValue));

watch(
  () => props.modelValue,
  (modelValue) => (input.value = parseValue(modelValue))
);
watch(
  input,
  (input) => {
    const invalid = Object.values(input).some((v) => typeof v !== 'number');
    invalid || emit('update:modelValue', prepareInput(input));
  },
  { deep: true }
);
</script>

<template>
  <div class="flex gap-12">
    <FormDayField
      class="grow"
      placeholder="Day"
      v-if="!disableDay"
      v-model="input.date"
      :clearable="clearable"
      :disabled="disabled"
      :month="input.month"
      :year="input.year"
    />
    <ClientOnly>
      <FormMonthField
        class="grow"
        placeholder="Month"
        v-model="input.month"
        :clearable="clearable"
        :disabled="disabled"
      />
    </ClientOnly>
    <FormYearField
      class="grow"
      placeholder="Year"
      v-model="input.year"
      :clearable="clearable"
      :disabled="disabled"
    />
  </div>
</template>
